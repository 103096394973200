const HTTP_URL=process.env.VUE_APP_API
import {request, METHOD} from '@/utils/request'

export async function GetAdvConfig(params) {
  return request(`${HTTP_URL}api/getLotteryConfig`, METHOD.POST,params)
}

export async function GetLotteryStatus(params) {
  return request(`${HTTP_URL}dz/index/checkOrderLotteryStatus`, METHOD.POST,params)
}

export async function orderLottery(params) {
  return request(`${HTTP_URL}dz/index/orderLottery`, METHOD.POST,params)
}
